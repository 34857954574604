<template>
  <div class="test-content">
      <div class="test-header padding-test">
        <div class="test-header-group">
        <div v-bind:class="{ 'test-header-btn-active': tab === 'info' }" class="test-header-btn" @click="tab = 'info'">{{ $t('profile.test.info') }}</div>
        <div v-bind:class="{ 'test-header-btn-active': tab === 'questions' }" class="test-header-btn" @click="tab = 'questions'">{{ $t('profile.test.questions') }}</div>
        </div>
          <el-button @click="$bvModal.show('modal-add-question')" v-bind:class="{ 'd-none': tab === 'info' }" type="danger" class="add-questions-btn" icon="el-icon-plus">{{ $t('profile.test-bank.add') }}</el-button>
      </div>
    <div v-show="tab === 'info'">
        <form-group class="tests-form-group" :label="$t('profile.test.name-ru')" >
            <el-input v-model="test.title_ru" class="w-100" :placeholder="$t('profile.test.name-ru')" ></el-input>
        </form-group>
        <form-group class="tests-form-group" :label="$t('profile.test.name-kz')" >
            <el-input v-model="test.title_kz" class="w-100" :placeholder="$t('profile.test.name-kz')" ></el-input>
        </form-group>
        <form-group class="tests-form-group" :label="$t('profile.test.desc-ru')" >
            <quill-editor
                v-model="test.description_ru"
                :options="ruEditorOption"
                @ready="onEditorReady($event)"
            />
        </form-group>
        <form-group class="tests-form-group" :label="$t('profile.test.desc-kz')" >
            <quill-editor
                v-model="test.description_kz"
                :options="kzEditorOption"
                @ready="onEditorReady($event)"
            />
        </form-group>
      <form-group class="tests-form-group" :label="$t('profile.test-bank.subject')">
        <el-select
            @change="getTopics"
            v-model="test.subject_id"
            class="w-100"
            :placeholder="$t('profile.test-bank.subject')" >
            <el-option v-for="(item, key) in subjects"
                       :key="key"
                       :label="item"
                       :value="key">
            </el-option>
        </el-select>
      </form-group>
      <form-group class="tests-form-group" :label="$t('profile.test-bank.theme')">
        <el-select v-model="test.topic_id" class="w-100" :placeholder="$t('profile.test-bank.theme')">
            <el-option v-for="item in topics"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
        </el-select>
      </form-group>
        <form-group class="tests-form-group" :label="$t('profile.test.complexity')" >
            <el-select v-model="test.complexity" class="w-100" :placeholder="$t('profile.test.complexity')" >
                <el-option v-for="(item, key) in complexities"
                           :key="key"
                           :label="item"
                           :value="key">
                </el-option>
            </el-select>
        </form-group>
        <form-group class="tests-form-group" :label="$t('profile.test.number_of_passes')" >
            <el-select v-model="test.number_of_passes" class="w-100" :placeholder="$t('profile.test.number_of_passes')" >
                <el-option v-for="(item, key) in numberOfPasses"
                           :key="key"
                           :label="item"
                           :value="key">
                </el-option>
            </el-select>
        </form-group>
        <form-group class="tests-form-group" :label="$t('profile.test.show_right')" >
            <el-select v-model="test.show_right" class="w-100" :placeholder="$t('profile.test.show_right')" >
                <el-option :label="$t('profile.test.no')" :value="0"></el-option>
                <el-option :label="$t('profile.test.yes')" :value="1"></el-option>
            </el-select>
        </form-group>
        <form-group class="tests-form-group" :label="$t('profile.test.show_comments')" >
            <el-select v-model="test.show_comments" class="w-100" :placeholder="$t('profile.test.show_comments')" >
                <el-option :label="$t('profile.test.no')" :value="0"></el-option>
                <el-option :label="$t('profile.test.yes')" :value="1"></el-option>
            </el-select>
        </form-group>
      <div class="padding-test test-bank-btns-bottom" style="display:flex">
          <el-button @click="saveTest" class="btn-purple">{{ $t('profile.test-bank.save') }}</el-button>
          <el-button @click="$router.push({ name: 'tests' })" class="btn-red">{{ $t('profile.test-bank.reset') }}</el-button>
      </div>
      <div class="test-wave"></div>
    </div>
    <div style="margin-bottom: 38px" v-show="tab === 'questions'" class="padding-test">
        <div class="tests-table-block">
            <el-table
                v-loading="loading"
                class="tests-table"
                :data="test.questions"
                style="width: 100%">
                <el-table-column
                    prop="id"
                    :label="$t('profile.test-bank.id')"
                    width="70">
                </el-table-column>
                <el-table-column
                    prop="title"
                    :label="$t('profile.test-bank.question')"
                    width="370">
                </el-table-column>
                <el-table-column
                    prop="subject"
                    width="200"
                    :label="$t('profile.test-bank.subject')">
                </el-table-column>
                <el-table-column
                    prop="topic"
                    width="240"
                    :label="$t('profile.test-bank.theme')">
                </el-table-column>
                <el-table-column
                    fixed="right"
                    class="text-center"
                    label-class-name="text-center pl-1"
                    :label="$t('profile.test-bank.options')"
                    width="100">
                    <template slot-scope="scope">
                        <div class="actions text-center">
                            <el-button type="danger" @click="deleteQuestion(scope.row.id)"><i class="delete-icon"></i></el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <b-pagination
            v-if="total > perPage"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            last-number
            first-number
            hide-goto-end-buttons
            align="center"
            next-text=">"
            prev-text="<"
        ></b-pagination>
        <div class="test-bank-btns-bottom">
            <el-button class="test-bank-btn-save" @click="saveTest">
                {{ $t('profile.test-bank.save') }}
            </el-button>
            <el-button class="test-bank-btn-cancel" @click="$router.push({ name: 'tests' })">
                {{ $t('profile.test-bank.reset') }}
            </el-button>
        </div>
    </div>

    <b-modal id="modal-add-question" hide-footer hide-header centered class="modal-add-hw" size="lg">
        <i class="el-icon-close cursor-pointer float-right" @click="$bvModal.hide('modal-add-question')"></i>
        <AddQuestions :test-questions="test.questions" :quiz-id="test.id" :show-tab="false" />
    </b-modal>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Vue from "vue"
import AddQuestions from "../../components/AddQuestions"
import { quillEditor } from "vue-quill-editor"

export default {
    components: { AddQuestions, quillEditor },
    data() {
        return {
            ruEditorOption: {
                placeholder: this.$t('profile.test.desc-ru'),
                modules: {
                    toolbar: [['bold', 'italic', 'image']],
                    resize: {}
                }
            },
            kzEditorOption: {
                placeholder: this.$t('profile.test.desc-kz'),
                modules: {
                    toolbar: [['bold', 'italic', 'image']],
                    resize: {}
                }
            },
            loading: false,
            test: {},
            filter: {
                id: '',
                name: '',
                type: '',
                subject: '',
                topic: '',
                complexity: '',
            },
            tab: 'info',
            currentPage: 1,
            total: 0,
            perPage: 1,
            subjects: [],
            topics: [],
            complexities: {
                1: 'Легкий',
                2: 'Средний',
                3: 'Сложный'
            },
            numberOfPasses: {
                1: '1',
                2: '2',
                3: '3',
                4: '4',
                5: '5',
            },
        }
    },
    mounted() {
        let id = this.$route.params.id
        this.$http.get(`${window.API_ROOT}/api/quiz/edit/${id}`)
            .then((res) => {
                this.loading = false;
                this.test = res.body.data
                this.subjects = res.body.subjects
                this.topics = res.body.topics
            }).catch(() => {
                this.loading = false;
            })
    },
    methods: {
        saveTest() {
            let id = this.$route.params.id;
            this.$http.post(`${window.API_ROOT}/api/quiz/update/${id}`, this.test)
                .then((res) => {
                    this.loading = false;
                    if (res.body.status === 'success') {
                        Vue.toastr({
                            message: 'Успешно!',
                            description: 'Тест сохранен',
                            type: 'success'
                        });
                    }
                  this.$router.push({ name: 'tests' })
              }).catch(() => {
                  this.loading = false;
              })
        },
        deleteQuestion(id) {
            this.test.questions.forEach((question, key) => {
                if (question.id === id) {
                    this.test.questions.splice(key, 1);
                }
            })
        },
        getTopics(subjectId) {
            this.filter.topic = '';
            this.test.topic_id = '';
            this.topics = [];
            this.$http.get(`${window.API_ROOT}/api/reference/topics/${subjectId}`)
                .then((res) => {
                    this.topics = res.body.data
                })
                .catch(() => {
                })
        },
        onEditorReady(quill) {
            quill.enable(false)
            quill.on('selection-change', function () {
                quill.enable(true)
            })
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-table::before,
/deep/ .el-table__fixed-right::before,
/deep/ .el-table__fixed::before{
    content: none;
}
/deep/ .el-table__body tr.hover-row.current-row>td,
/deep/ .el-table__body tr.hover-row.el-table__row--striped.current-row>td,
/deep/ .el-table__body tr.hover-row.el-table__row--striped>td,
/deep/ .el-table__body tr.hover-row>td{
    background-color: initial;
}
/deep/ .el-table {
    margin-top: 2rem;
    tbody {
        td {
            border: none !important;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #473F95;
            border-radius: 4px;
            padding-left: 8px;
            padding-right: 8px;
            div{
                padding: 0;
            }
        }
    }
    th {
        border: none !important;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 35px;
        color: #473F95;
        border-radius: 4px;
        padding-right: 5px;
        div {
            border-radius: 4px;
            padding: 8px;
            background: #E3E2F2;
        }
    }
}
.subject-width{
  width:200px
}
.theme-width{
  width:218px
}
.padding-test{
  padding-left: 30px;
  padding-right: 30px;
}
.test-wave{
  margin-top: 2rem;
  height: 90px;
  width:100%;
  background-image: url("/images/footer-wave-ktp.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.cursor-pointer {
    cursor: pointer;
}
.modal-add-hw__content {
    clear: both;
}
.el-icon-close {
    background: url("/images/profile/close-lila.svg") no-repeat center;
    text-indent: -9999px;
    display: block;
    width: 24px;
    height: 24px;
}
.tests-table .actions .el-button{
    padding: 6px;
    border: none;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: transparent;
}
.tests-table .actions .el-button:focus {
    background-color: transparent;
}
.tests-table .actions .el-button.el-button--default:hover{
    background: #ecf5ff;
}
.tests-table .actions .el-button.el-button--danger:hover{
    background: #fde3e3;
}
.tests-table .actions .edit-icon{
    background: url("/images/profile/edit.svg") no-repeat center;
    background-size: cover;
    width: 18px;
    height: 18px;
    display: block;
    margin: 0 auto;
}
.tests-table .actions .delete-icon{
    background: url("/images/profile/delete.svg") no-repeat center;
    background-size: cover;
    width: 18px;
    height: 18px;
    display: block;
    margin: 0 auto;
}
.tests-form-group{
  color: #473F95;
  margin-top: 20px;
  padding-left: 38px;
  padding-right: 38px;
}
.tests-table{
  margin-top: 27px;
  width:100%;
  thead{
    td{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 35px;
      color: #473F95;

      border-radius: 4px;
      padding-right: 5px;

      div{
        border-radius: 4px;
        padding-left: 8px;
        background: #E3E2F2;
      }
    }
    td:last-child {
      padding-right: 0;
    }
  }

  tbody{
      td{
        padding-top: 20px;
        max-width: 370px;
        min-width: 66px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #473F95;

        border-radius: 4px;
        padding-right: 5px;
        padding-left: 8px;
      }
        td:last-child {
            padding-right: 0;
        }

  }
}
.tests-table thead .tests-table__header:nth-child(1),
.tests-table tbody td:nth-child(1){
    min-width: 66px;
}
.tests-table thead .tests-table__header:nth-child(2),
.tests-table tbody td:nth-child(2){
    min-width: 368px;
}
.tests-table thead .tests-table__header:nth-child(3),
.tests-table tbody td:nth-child(3){
    min-width: 196px;
}
.tests-table thead .tests-table__header:nth-child(4),
.tests-table tbody td:nth-child(4){
    min-width: 242px;
}
.btn-red{
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  width:150px;
  color: white;
  border-color: #D23168;
  background-color: #D23168;
  text-align: center;
  display: flex;
  justify-content: center;
  span{
    padding-right: 10px;
    font-weight: bold;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  div{
    line-height: 38px;
    font-size: 16px;
  }
}
.btn-purple{
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  width:150px;
  color: #473F95;;
  background-color: #EEEAFB;
  text-align: center;
  display: flex;
  justify-content: center;
  span{
    padding-right: 10px;
    font-weight: bold;
    font-size: 27px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
}
.test-header-group{
  display: flex;
}
.test-header-btn{
  min-width:59px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #473F95;
  border-bottom: 3px solid transparent;
}
.test-header-btn-active{
  border-bottom: 3px solid #473F95;
}
.test-header{
  display: flex;
  justify-content:space-between;
}
.test-content{
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding-top: 28px;
  max-width:880px;
  width: -webkit-fill-available;
  background-color: white;
  margin: 0 auto;
}
.tests-table-block {
    overflow-x: auto;
    padding-left: 0;
}
.pagination {
    border: none;
    box-shadow: none;
    padding-top: 40px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
.test-bank-btns-bottom {
    margin-top: 38px;
    text-align: center;
    justify-content: center;
}
.test-bank-btn-save {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #EEEAFB;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
    margin-right: 10px;
}
.test-bank-btn-cancel {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #D23168;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
}
.modal-add-hw__close {
    background-color: transparent;
    border-radius: 0;
    min-width: auto;
    width: fit-content;
    padding: 0;
    border: none;
    margin-left: auto;
}
.form-group {
    display: flex;
    flex-direction: column;
}
.modal-add-hw__select {
    border-bottom: 2px solid #E3E2F2;
    padding-bottom: 13px;
}
.modal-add-hw__select-btns {
    padding-top: 19px;
    padding-bottom: 67px;
}
.modal-add-hw__apply {
    background: #EEEAFB;
    border-radius: 5px;
    margin-right: 10px;
    color: #473F95;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    padding: 14px 36px;
}
.modal-add-hw__reset {
    color: #fff;
    font-family: "Inter", "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background: #D23168;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
}
.add-hw-table {
    width: 100%;
    border-spacing: 5px;
    border-collapse: separate;
}
.add-hw-table-wrapper.loading {
    position: relative;
}
.add-hw-table-wrapper.loading::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('/images/preloader.gif');
    background-color: #ffffff9e;
    background-size: 25%;
    background-position: center;
    background-repeat: no-repeat;
}
.add-hw-table__header {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 8px;
    border-radius: 4px;
    background: #E3E2F2;
}
.add-hw-table__check-box {
    width: 48px;
    text-align: center;
}
.add-hw-table__theme {
    width: 309px;
}
.add-hw-table__subject {
    width: 196px;
}
.add-hw-table__task-type {
    width: 242px;
}
.add-hw-table__theme,
.add-hw-table__subject,
.add-hw-table__task-type {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 12px;
}
.add-hw-table__theme > div,
.add-hw-table__subject > div,
.add-hw-table__task-type > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #473F95;
    border-color: #473F95;
}
/deep/ .el-checkbox__inner:hover {
    border-color: #473F95;
}
/deep/ .el-checkbox__inner {
    width: 19px;
    height: 19px;
}
/deep/ .el-checkbox__inner::after {
    height: 9px;
    left: 6px;
    top: 2px;
    width: 4px;
}
.modal-add-hw__footer {
    text-align: center;
    margin-bottom: 18px;
}
.modal-add-hw__cancel {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 12px;
    background-color: transparent;
    padding: 13px 46px;
}
.modal-add-hw__save {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid #473F95;
    background: #473F95;
    border-radius: 5px;
    padding: 13px 35px;
}
/deep/ #modal-add-question .modal-body {
    position: relative;
    background-image: url("/images/profile/wave-hw-modal.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}
.add-questions-btn {
    float: right;
    background: #D23168;
    border-color: #D23168;
    font-family: 'Roboto', sans-serif;
}
.add-questions-btn i{
    font-weight: bold;
}
.modal-add-hw__footer-btns {
    text-align: center;
    margin-top: 100px;
}
/deep/ .modal-add-hw__content .form-group .signin-label {
    text-align: left;
}
@media (max-width: 991px) {
    .test-content {
        box-shadow: none;
        max-width: none;
        width: 100%;
        padding-top: 0;
    }
    .padding-test {
        padding: 0;
    }
    .tests-table-block {
        margin-right: 0;
        margin-left: 0;
    }
    .test-header {
        flex-direction: column;
        width: fit-content;
        margin: 0 auto;
    }
    .test-header .btn-red {
        width: 100%;
    }
    .test-header-group {
        margin-bottom: 32px;
    }
    .test-header-group .test-header-btn:first-child {
        margin-right: 8px;
    }
    .test-header-group .test-header-btn:last-child {
        width: 135px;
        justify-content: center;
    }
    .test-header-btn {
        color: #473F95;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.408px;
        padding: 9px 14px;
        background: #FDFEFF;
        border: 1px solid #473F95;
        box-sizing: border-box;
        backdrop-filter: blur(20px);
        border-radius: 12px;
    }
    .test-header-btn-active {
        color: #FFFFFF;
        background: #473F95;
        border: 1px solid #473F95;
    }
    .test-wave {
        display: none;
    }
    .btn-red--hide {
        display: none;
    }
    .tests-form-group {
        padding: 0;
    }
    /deep/ .page-item.active .page-link {
        z-index: 0;
    }
        .modal-add-hw__footer-btns {
        margin-top: 60px;
        margin-bottom: 80px;
    }
    .modal-add-hw__footer-btns .modal-add-hw__save,
    .modal-add-hw__footer-btns .modal-add-hw__cancel {
        padding: 13px 24px;
    }
    .modal-add-hw__select-btns {
        text-align: center;
    }
    .add-hw-table-wrapper {
        overflow-x: auto;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(1) {
        min-width: 48px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(2) {
        min-width: 66px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(3) {
        min-width: 368px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(4) {
        min-width: 196px;
    }    
    .add-hw-table > tr > .add-hw-table__header:nth-child(5) {
        min-width: 242px;
    }
    .add-hw-table > tr > .add-hw-table__header:nth-child(6) {
        min-width: 242px;
    }
    #modal-add-hw .pagination {
        padding-top: 28px;
    }
    .pagination {
        margin-bottom: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
}
@media (max-width: 767px) {
    .pagination {
        margin-bottom: 0;
    }
    /deep/ .pagination .page-item .page-link {
        font-size: 14px;
        line-height: 16px;
        min-width: 38px;
    }
}

</style>