<template>
    <div class="add-questions-wrapper">
        <div class="form-group">
            <label class="form-label">{{ $t('profile.test.id') }}</label>
            <el-input
                v-model="filter.id"
                :placeholder="$t('profile.detailed-lesson.name-placeholder')"
            >
            </el-input>
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('profile.test.question-name') }}</label>
            <el-input
                v-model="filter.title"
                :placeholder="$t('profile.test.question-name')"
            >
            </el-input>
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('profile.detailed-lesson.subject-title') }}</label>
            <el-select
                clearable
                @change="getTopics"
                :placeholder="$t('profile.detailed-lesson.subject-placeholder')"
                v-model="filter.subject">
                <el-option
                    v-for="(item, key) in subjects"
                    :key="key"
                    :label="item"
                    :value="key">
                </el-option>
            </el-select>
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('profile.detailed-lesson.theme-title') }}</label>
            <el-select
                clearable
                :placeholder="$t('profile.detailed-lesson.theme-placeholder-2')"
                v-model="filter.topic">
                <el-option
                    v-for="item in topics"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('profile.test.type') }}</label>
            <el-select
                clearable
                :placeholder="$t('profile.test.type')"
                v-model="filter.type">
                <el-option
                    v-for="(item, key) in types"
                    :key="key"
                    :label="item"
                    :value="key">
                </el-option>
            </el-select>
        </div>
        <div class="form-group">
            <label class="form-label">{{ $t('profile.test.complexity') }}</label>
            <el-select
                clearable
                :placeholder="$t('profile.test.complexity')"
                v-model="filter.complexity">
                <el-option
                    v-for="(item, key) in complexities"
                    :key="key"
                    :label="item"
                    :value="key">
                </el-option>
            </el-select>
        </div>
        <div class="modal-add-hw__select-btns">
            <el-button class="modal-add-hw__apply" @click="sendFilter">
                {{ $t('profile.detailed-lesson.apply') }}
            </el-button>
            <el-button type="danger" class="modal-add-hw__reset" @click="resetFilter">
                {{ $t('profile.detailed-lesson.reset') }}
            </el-button>
        </div>
        <div class="add-hw-table-wrapper">
            <el-table
                v-loading="filterLoading"
                class="tests-table"
                :data="questions"
                style="width: 100%">
                <el-table-column
                    label="id"
                    label-class-name="text-indent"
                    width="60"
                    prop="id">
                    <template slot-scope="scope">
                        <td class="add-hw-table__check-box">
                            <el-checkbox @change="toggleQuestion(scope.$index)" v-model="scope.row.checked"></el-checkbox>
                        </td>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="id"
                    width="60"
                    :label="$t('profile.test-bank.id')">
                </el-table-column>
                <el-table-column
                    prop="title"
                    width="200"
                    :label="$t('profile.test-bank.question')">
                </el-table-column>
                <el-table-column
                    prop="subject"
                    width="150"
                    :label="$t('profile.test-bank.subject')">
                </el-table-column>
                <el-table-column
                    prop="topic"
                    width="200"
                    :label="$t('profile.test-bank.theme')">
                </el-table-column>
                <el-table-column
                    prop="type"
                    width="150"
                    :label="$t('profile.test-bank.type')">
                    <template slot-scope="scope">
                        {{ types[scope.row.type] }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="complexity"
                    width="150"
                    :label="$t('profile.test.complexity')">
                    <template slot-scope="scope">
                        {{ complexities[Object.keys(complexities)[scope.row.complexity-1]] }}
                    </template>
                </el-table-column>
            </el-table>
            <b-pagination
                v-if="total > perPage"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                @change="getQuestions"
                last-number
                first-number
                hide-goto-end-buttons
                align="center"
                next-text=">"
                prev-text="<"
            ></b-pagination>

            <div class="modal-add-hw__select-btns text-center mt-4 mb-5 pb-5">
                <el-button type="primary" class="modal-add-hw__apply" @click="apply">
                    {{ $t('profile.test-bank.save') }}
                </el-button>
                <el-button type="danger" class="modal-add-hw__reset" @click="cancel">
                    {{ $t('profile.test-bank.cancel') }}
                </el-button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "AddQuestions",
    props: ['testQuestions', 'quizId', 'showTab'],
    event: ['close'],
    data() {
        return {
            filterLoading: false,
            total: 0,
            perPage: 0,
            currentPage: 1,
            filter: {
                id: '',
                title: '',
                subject: '',
                topic: '',
                type: '',
                complexity: '',
            },
            subjects: [],
            topics: [],
            types: [],
            complexities: {
                1: 'Легкий',
                2: 'Средний',
                3: 'Сложный'
            },
            questions: [],
            temp: [],
            testQuestionsOrig: [],
            tab: this.showTab
        }
    },
    mounted() {
        this.temp = this.testQuestions.slice()
        this.testQuestionsOrig = this.testQuestions.slice()
        this.getQuestions(1)
    },
    methods: {
        getQuestions(page) {
            let id = this.filter.id
            let title = this.filter.title
            let subjectId = this.filter.subject
            let topic = this.filter.topic
            let type = this.filter.type
            let complexity = this.filter.complexity
            let quizId = this.quizId
            if (!quizId) {
                quizId = ''
            }
            this.$http.get(`${window.API_ROOT}/api/question?page=${page}&id=${id}&subject_id=${subjectId}&topic_id=${topic}&title=${title}&complexity=${complexity}&type=${type}&quiz_id=${quizId}`)
                .then((res) => {
                    this.questions = res.body.data
                    this.total = res.body.meta.total
                    this.perPage = res.body.meta.per_page
                    this.subjects = res.body.subjects
                    // this.topics = res.body.topics
                    this.types = res.body.types
                    this.filterLoading = false
                })
                .catch(() => {
                    this.filterLoading = false
                })
                .finally(() => {
                    this.questions.forEach(question => {
                        let q = this.temp.find(q => q.id === question.id)
                        if (q) {
                            question.checked = true
                        }
                    })
                })
        },
        getTopics(subjectId) {
            this.filter.topic = '';
            this.topics = [];
            this.$http.get(`${window.API_ROOT}/api/reference/topics/${subjectId}`)
                .then((res) => {
                    this.topics = res.body.data
                })
                .catch(() => {
                })
        },
        toggleQuestion(index) {
            let question = this.questions[index]
            if (question.checked) {
                this.temp.push(question)
            } else {
                let quest = this.questions.find(q => q.id === question.id)
                this.deleteQuestion(quest.id)
            }
        },
        deleteQuestion(id) {
            this.temp.forEach((question, key) => {
                if (question.id === id) {
                    this.temp.splice(key, 1);
                }
            })
        },
        sendFilter() {
            this.filterLoading = true;
            this.currentPage = 1
            this.getQuestions(this.currentPage)
        },
        resetFilter() {
            this.filter.id = '';
            this.filter.title = '';
            this.filter.subject = '';
            this.filter.topic = '';
            this.filter.type = '';
            this.filter.complexity = '';
            this.currentPage = 1
            this.getQuestions(this.currentPage);
        },
        apply() {
            this.testQuestions.splice(0, this.testQuestions.length)
            this.temp.forEach(item => {
                this.testQuestions.push(item)
            })
            this.testQuestionsOrig = this.temp.slice()
            this.close()
        },
        cancel() {
            this.testQuestions.splice(0, this.testQuestions.length)
            this.testQuestionsOrig.forEach(item => {
                this.testQuestions.push(item)
            })
            this.close()
        },
        close() {
            if (this.showTab) {
                this.$emit('close')
            } else {
                this.$bvModal.hide('modal-add-question')
            }
        }
    }
}
</script>

<style lang="less" scoped>
.modal-add-hw__content {
    clear: both;
}
.add-questions-wrapper {
    width: 100%;
    clear: both;
}
.el-select {
    width: 100%;
}
.add-hw-table {
    width: 100%;
    border-spacing: 5px;
    border-collapse: separate;
}
/deep/ .cell.text-indent{
    text-indent: -999px;
}
/deep/ .el-table::before,
/deep/ .el-table__fixed-right::before,
/deep/ .el-table__fixed::before{
    content: none;
}
/deep/ .el-table__body tr.hover-row.current-row>td,
/deep/ .el-table__body tr.hover-row.el-table__row--striped.current-row>td,
/deep/ .el-table__body tr.hover-row.el-table__row--striped>td,
/deep/ .el-table__body tr.hover-row>td{
    background-color: initial;
}
/deep/ .el-table {
    margin-top: 2rem;
    tbody {
        td {
            border: none !important;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #473F95;
            border-radius: 4px;
            padding-left: 8px;
            padding-right: 8px;
            div{
                padding: 0;
            }
        }
    }
    th {
        border: none !important;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 35px;
        color: #473F95;
        border-radius: 4px;
        padding-right: 5px;
        div {
            border-radius: 4px;
            padding: 8px;
            background: #E3E2F2;
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}
.el-icon-close {
    background: url("/images/profile/close-lila.svg") no-repeat center;
    text-indent: -9999px;
    display: block;
    width: 24px;
    height: 24px;
}
.modal-add-hw__select-btns{
    margin-bottom: 20px;
}
.modal-add-hw__select-btns .el-button{
    font-family: 'Inter', Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-radius: 5px;
    padding: 14px 36px;
    border: none;
    background: #e3e2f2;
    color: #473F95;
}
.modal-add-hw__select-btns .el-button.el-button--danger{
    background: #d23169;
    color: #fff;
}
.modal-add-hw__select-btns .el-button.el-button--primary{
    color: #ffffff;
    background-color: #473F95;
}
.add-hw-table-wrapper.loading {
    position: relative;
}
.add-hw-table-wrapper.loading::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('/images/preloader.gif');
    background-color: #ffffff9e;
    background-size: 25%;
    background-position: center;
    background-repeat: no-repeat;
}
.add-hw-table__header {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 8px;
    border-radius: 4px;
    background: #E3E2F2;
}
.add-hw-table__check-box {
    width: 48px;
    text-align: center;
}
.add-hw-table__theme {
    width: 309px;
}
.add-hw-table__subject {
    width: 196px;
}
.add-hw-table__task-type {
    width: 242px;
}
.add-hw-table__theme,
.add-hw-table__subject,
.add-hw-table__task-type {
    color: #473F95;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 12px;
}
.add-hw-table__theme > div,
.add-hw-table__subject > div,
.add-hw-table__task-type > div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pagination {
     border: none;
     box-shadow: none;
     padding-top: 40px;
 }
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
</style>